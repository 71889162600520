import React from "react"
import SEO from "../components/seo"
import contactStyles from "../styles/contact.module.scss"
import Layout from "../components/layout"


const Contact = ((props) => {
  return (
    <Layout>
      <SEO title="Contact"/>
      <div className={contactStyles.maindiv}>
        {/* left div (service info) */}
        <div className={contactStyles.servinfodiv}>
          <div className={contactStyles.textdiv}>
            <div className={contactStyles.contacthead}>FIND US</div>
            <br/>
            
            <div>info@ladyonwheelsservices.co.za</div>

            <div className={contactStyles.textline}/>

            <div>ADDRESS:</div>
            <div><a>- 435 Aureole Avenue, North Riding, Johannesburg, Gauteng. 2091</a></div>
            <div><a>- 3744 Waterkloof Hill Estates, Rustenburg, Kroondal. 0300</a></div>

            <div className={contactStyles.textline}/>

            <div>TEL:</div>
            <div>Johannesburg: 010 446 0223</div>
            <div>Rustenburg: 014 880 0610</div>
            <div>Whatsapp: 062 192 8685</div>

            <div className={contactStyles.textline}/>

            <div>SOCIAL:</div>
            <div className={contactStyles.socialdiv}>
              <div className={contactStyles.socialdiv1}><a className={contactStyles.socialtag1}>TWITTER</a></div>
              <div className={contactStyles.socialdiv2}><a className={contactStyles.socialtag2}>FACEBOOK</a></div>
              <div className={contactStyles.socialdiv3}><a className={contactStyles.socialtag3}>INSTAGRAM</a></div>
            </div>
          </div>
        </div>
        {/* right div (quote) */}
        {/* <div className={contactStyles.quotediv}>
          <h3>CONTACT</h3>
          <form className={contactStyles.formdiv}>
              <div className={contactStyles.namediv}>
                <div className={contactStyles.firstnamelabel}><label for="firstname">First Name*</label></div>
                <div className={contactStyles.firstnameinput}><input style={{width:'100%',border:'solid 2px black',paddingLeft:'4px'}} type="text" id="firstname" name="firstname" placeholder="your first name..."></input></div>
                <div className={contactStyles.lastnamelabel}><label for="lastname">Last Name*</label></div>
                <div className={contactStyles.lastnameinput}><input style={{width:'100%',border:'solid 2px black',paddingLeft:'4px'}} type="text" id="lastname" name="lastname" placeholder="your last name..."></input></div>
              </div>
              <div className={contactStyles.contactdiv}>
                <div className={contactStyles.tellabel}><label for="telnumber">Contact Number*</label></div>
                <div className={contactStyles.telinput}><input style={{width:'100%',border:'solid 2px black',paddingLeft:'4px'}} type="number" id="telnumber" name="telnumber" placeholder="your contact number..."></input></div>
                <div className={contactStyles.emaillabel}><label for="form-email">Email*</label></div>
                <div className={contactStyles.emailinput}><input style={{width:'100%',border:'solid 2px black',paddingLeft:'4px'}} type="email" id="form-email" placeholder="your email address..."/></div>
              </div>

              <div className={contactStyles.commentdiv}>
                <div className={contactStyles.commentlabel}><label for="form-address">Message</label></div>
                <div className={contactStyles.commentinput}><textarea style={{width:'100%',border:'solid 2px black',paddingLeft:'4px'}} type="text" rows="3" id="form-address" placeholder="leave a mesaage here..."/></div>
              </div>
              
              <div className={contactStyles.formbutton}><a type="submit" className={contactStyles.formsubmitbut}>SUBMIT</a></div>
          </form>
        </div> */}
      </div>
    </Layout>
  )
}
)

export default Contact